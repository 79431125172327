import sleepFor from "../../utilities/sleep";
import StyledButton from "../../utilities/styledButton";
import menuLogo from "./menu50.svg"
import { useState } from "react";
import closeLogo from "./close.svg"

const NavBar = (props) => {

    const [innerNavBarHeight, setInnerNavBarHeight] = useState(props.windowWidth > 1100 ? 60 : 80);
    const [innerNavBarWidth, setInnerNavBarWidth] = useState(80); 
    const [outerNavBarHeight, setOuterNavBarHeight] = useState(10);
    const buttonStyle = {
        height: "100%",
        width: "clamp( 10rem, 10%, 20rem)",
        fontSize: "1.2rem",
        backgroundColor: "rgba(0, 0, 0, 0)",
        color: "white",
    };

    let buttons = props.buttonList.map((button) =>
        <StyledButton
            animationRate={0.02}
            maxScale={1.3}
            style={buttonStyle}
            content={button.content}
            func={props.func}
            key={button.elementKey}
            animateClick={true}
        />
    );

    const navBarStyle = {
        width: "100%",
        height: outerNavBarHeight + "dvh",
        position: "absolute",
        top: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 3,
    };


    let innerNavBarStyle = {
        display: "flex",
        justifyContent: props.windowWidth > 1100 ? "end" : "start",
        alignItems: "center",
        width: "clamp(" + props.minContentWidth + "  , " + innerNavBarWidth + "% ,1600px)",
        height: innerNavBarHeight + "%",
        backgroundColor: "#1a1a1a",
        borderRadius: ".5rem",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",    }
    let MobileMenuButtonStyle = {
        backgroundColor: "transparent",
        margin: "40px",
    }

    const animateNavBarIn = async () => {
        for(let i = innerNavBarHeight; i<= 100; i+=2){
            setInnerNavBarHeight(i);
            setInnerNavBarWidth(i);
            await sleepFor(1);
        }
        for(let i = 10; i <= 100; i+=3){
            setOuterNavBarHeight(i);
            await sleepFor(1);
        }
    }


    const animateNavBarOut = async () => {
        for(let i = 100; i >= 10; i-=3){
            setOuterNavBarHeight(i);
            await sleepFor(1);
        }
        for(let i = innerNavBarHeight; i >= 80; i-=2){
            setInnerNavBarHeight(i);
            setInnerNavBarWidth(i);
            await sleepFor(1);
        }
        setNavBarContent(mobileMenu);
    }
 
    const mobileMenuButtons = props.buttonList.map((button) =>
        <StyledButton
            animationRate={0.02}
            maxScale={1.3}
            style={{
                ...buttonStyle, 
                width: "20dvh", 
                height: "10dvh",
                fontSize: "3rem", 
                display: "flex", 
                justifyContent: "start",
            }}
            content={button.content}
            func={() => {
                animateNavBarOut();
                button.func();
            }}
            key={button.elementKey}
            animateClick={false}
        />)

    let mobileMenu = <StyledButton
        animationRate={0.05}
        maxScale={1.5}
        style={MobileMenuButtonStyle}
        content=<img src={menuLogo} alt="Menu" height="50vw" />
        func={() => { 
            animateNavBarIn();
            setNavBarContent(
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100dvw",
                }}> 
                    <div style={{
                        height: "95dvh",
                        display: "flex", 
                        flexDirection: "column",
                        marginTop: "5dvh", 
                        marginLeft: "5dvh"
                    }}> 
                    {mobileMenuButtons}
                    </div>
                        <StyledButton 
                        animationRate={0.02}
                        maxScale={1.3}
                        style={{
                            ...buttonStyle, 
                            width: "10dvh", 
                            height: "10dvh",
                            fontSize: "3rem", 
                            display: "flex", 
                            justifyContent: "center",
                        }}
                        content=<img src={closeLogo} height="40%" alt="X"/>
                        func={() => {
                            animateNavBarOut();
                        }}
                        animateClick={false}
                        />
                </div>
            );
        }}
        key=""
        animateClick={true}
    />


    const [navBarContent, setNavBarContent] = useState(props.windowWidth > 1000 ? buttons : mobileMenu);

    return <nav style={navBarStyle}>
        <div style={innerNavBarStyle}>
            {navBarContent}
        </div>
    </nav>
}
export default NavBar;
