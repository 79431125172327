import cppLogo from "./cpp_logo.svg"
import reactLogo from "./React.svg"
import nodeLogo from "./node-js.svg"
import cssLogo from "./icons8-css-logo.svg"
import jsLogo from "./javascript-1.svg"
import linuxLogo from "./linux-tux.svg"


import StyledButton from "../../utilities/styledButton"

const Skill = (props) => {


    let skillCardStyle = {
        height: "90%",
        border: "2px solid white",
        borderRadius: "2rem",
        marginLeft: ".5rem",
        padding: "2rem",
        backgroundColor: "#1a1a1a",
        // width: "clamp( 300px, 30%, 600px)",
        minWidth: "clamp( 300px, 30%, 600px)",

    }

    return <StyledButton 
            animationRate={0.005} 
            preventAnimationClick={props.preventAnimationClick}
            maxScale={1.05}
            style={skillCardStyle} 
            content={<img height="80%" src={props.svg} alt={props.alt} />}
            func={props.func}
            key={props.elementKey}
            />
    
}

const SkillScroll = (props) => {

    const skills = [
        {
            name: "C++", 
            logo: cppLogo,
            func: () => {},
        },
        {
            name: "Javascript", 
            logo: jsLogo,
            func: () => {},
        },
        {
            name: "React JS", 
            logo: reactLogo,
            func: () => {},
        },
        {
            name: "Node JS", 
            logo: nodeLogo,
            func: () => {},
        },
        {
            name: "CSS", 
            logo: cssLogo,
            func: () => {},
        },        {
            name: "Linux", 
            logo: linuxLogo,
            func: () => {},
        },
    ]

    let scrollStyle = {
        height: props.height,
        backgroundColor: "#292828",
        border: "0px solid #292828",
        borderRadius: "2rem",
        display: "flex",
        alignItems: "center",
        overflow: "scroll",
        width: "100%"
    }
    // let mySkills = props.skills.map((skill) => Skill(skill.elementKey, skill.content, skill.func))


    const skillButtons = skills.map((skill, index) => <Skill preventAnimationClick={true} key={index++} elementKey={index++} svg={skill.logo} alt={skill.name} func={skill.func}/>)
    return <div style={scrollStyle}>
        {skillButtons}
    </div>
}

export default SkillScroll;