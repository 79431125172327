import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCrqOwXUum_xRSgMzSIm9Q6bwsm9jupRqc",
  authDomain: "main-website-5d83f.firebaseapp.com",
  projectId: "main-website-5d83f",
  storageBucket: "main-website-5d83f.appspot.com",
  messagingSenderId: "461809000785",
  appId: "1:461809000785:web:6c8143ded05a5c49f0b370",
  measurementId: "G-TRS3YFJTJP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
