import { useState } from "react";
const OnSwipeY = (props) => {

    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState("bruh");
    const [dontAnimate, setDontAnimate] = useState(false)

    // let dontAnimate = false
    const startTouch = (touchHandle) => {
        if(props.swipeBlockerRef !== undefined)
        {
            setDontAnimate(props.swipeBlockerRef.current && props.swipeBlockerRef.current.contains(touchHandle.target))
        }
        console.log(dontAnimate);
        setTouchEnd(null);
        setTouchStart(touchHandle.changedTouches[0].clientY);

    }

    const moveTouch = (e) => setTouchEnd(e.targetTouches[0].clientY)

    const endTouch = () => {
        let distance = touchEnd !== null ? touchEnd - touchStart : 0;   
        if(!dontAnimate)
        {                            
            if(distance > props.minDistanceToSwipe)
            {
                props.SwipeDown()
            }else if(distance * -1 > props.minDistanceToSwipe ){
                props.SwipeUp()

            }
        }
        setTouchStart(null)
        setTouchEnd(null);
    }
    
    return <div style={props.style} width={props.width} height={props.height} onTouchMove={moveTouch} onTouchStart={(e) => {
        startTouch(e); 

    }} onTouchEnd={endTouch} onWheel={props.onWheel}>
        {props.children}
    </div>
}

export default OnSwipeY;