import SkillScroll from "./skillsScroll"

const detailsAboutMe = (minContentWidth, contentWidth, scrollBlockerRef) => {
    let myDetailsStyle = {
        width: "clamp(" + minContentWidth + " ," + contentWidth + ",1600px)",
        backgroundColor: "#1a1a1a",
        color: "white",
        border: "0px solid #363434",
        borderRadius: ".5rem",
        display: "flex",
        justifyContent: "center",
        height: "85svh",
        marginBottom: "5svh",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",   
    }

    let innerDetailsStyle = {
        width: "clamp( 800px , 80% , 1600px)",
        fontSize: "clamp(28px, 2dvh, 100px)",
        marginTop: "20px",
        marginLeft: "1rem",
        marginRight: "1rem",
        textAlign: "justify",
        fontWeight: "300", 
    }

    let titleSyle = {
        fontWeight: "600",
        fontSize: "clamp(24px , 5dvh , 120px)",
    }
    return <div style={myDetailsStyle}>
        <div style={innerDetailsStyle}>
            <div style={titleSyle}>
            • About Me
            </div>
            <br />
            <div style={{
                marginLeft: "1rem",
                fontStyle: "italic"
            }}>
                “Never Stop Learning”
            </div >
            <p>
                Passionate about Information Technology and always happy to learn
                something new. When a specific problem arises, I prefer using my creativity to find a
                solution.
            </p>
            {/* <p>
                My passion arises from when I discovered programming in my high school classes.
                I am experienced with various systems and techniques from this domain of activity
                and will always be open for something new.
            </p> */}
            <div style={titleSyle}>
            • My Skills
            </div>
                <p>
                    I've been working the following technologies for the past couple of years:
                </p>
                <div ref={scrollBlockerRef}>
                    <SkillScroll height="20dvh"/>
                    {/* Over here we'll have the horizontal scrollable list (including technologies that I'm good with) */}
                </div>
        </div>
    </div>
}               

export default detailsAboutMe; 