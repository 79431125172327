import { useState } from "react"
import sleepFor from "./sleep"


const StyledButton = (props) => {

    const [buttonScale, setButtonScale] = useState(1);

    const animateButtonScale = async (size) => {
        let valueToAddToSize = props.animationRate;
        const incrementOrDecrement = size > buttonScale ? 1 : -1;
        valueToAddToSize *= incrementOrDecrement;
        let compareCurrentScaleToSize = true;
        for (let i = buttonScale; compareCurrentScaleToSize; i += valueToAddToSize) {
            compareCurrentScaleToSize = size > buttonScale ? i < size : i > size;
            await sleepFor(1);
            setButtonScale(i);
        }
    }
    let buttonStyle = {
        transform: "scale(" + buttonScale + ")",
        margin: "0px",
        border: "0px",
        padding: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: '"Roboto",monospace',
        ...props.style
    };

    return <button key={props.key} style={buttonStyle} onMouseEnter={() => { animateButtonScale(props.maxScale) }} onMouseLeave={() => { animateButtonScale(1) }}
        onClick={async () => {
            if (props.animateClick === true) {
                await animateButtonScale(1);
            }
            if (props.func !== undefined) {
                props.func();
            }
        }}
        onTouchStart={async () => {
            if (props.animateClick === true) {
                await animateButtonScale(1);
            }
            if (props.func !== undefined) {
                props.func();
            }
        }}
    >
        {props.content}
    </button>
}

export default StyledButton;