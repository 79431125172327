import contactMe from "./components/contact";
import LandingPage from "./components/landingPage";
import NavBar from "./components/navBar/navBar";
import { useState } from "react";


const getBackgroundGradientString = (bgGradient) => {
  return "linear-gradient(" + bgGradient.degrees + "," + bgGradient.dark + " " + bgGradient.darkPos + ", " + bgGradient.light + " " + bgGradient.ligthPos + ")"
}

function App() {

  let minimumContentWidth = 980; //Basically width of a mobile screenm

  const backrgoundGradient = {
    dark: "#1c1c1c",
    light: "#292828",
    darkPos: "0%",
    ligthPos: "100%",
    degrees: "180deg"
  };

  const windowStyle = {
    backgroundSize: "cover",     
    backgroundRepeat: "no-repeat",
    height: "100dvh",
    width: "100dvw",
    fontFamily: '"Montserrat", "Roboto", monospace',
    background: getBackgroundGradientString(backrgoundGradient),

  }

  const [viewingPage, setViewingPage] = useState(< LandingPage minContentWidth={minimumContentWidth + "px"} contentWidth="80%" />)


  let navButtons = [
    {
      elementKey: 0,
      content: "My Experience",
      func: () => {console.log("Working on it....")}
    },
    {
      elementKey: 1,
      content: "Contact",
      func: () => { setViewingPage(contactMe) }
    },
    {
      elementKey: 2,
      content: "About Me", 
      func: () => { setViewingPage(< LandingPage minContentWidth={minimumContentWidth + "px"} contentWidth="80%" />)}
    }]



  return (

    <div style={windowStyle}>
      <NavBar windowWidth={window.innerWidth} buttonList={navButtons} minContentWidth={minimumContentWidth + "px"} />
      {viewingPage}
    </div>
  );
}



export default App;
