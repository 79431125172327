
const initialLandingPageContent = (contentWidth) => {

    let initialLandingPageContentStyle = {
        width: "clamp(980px  ," + contentWidth + ",1600px)",    
        // backgroundColor: "blue",
        marginLeft: "5rem",
        height: "clamp(100px, 50dvh, 800px)",
        color: "white",
        // textAlign: "justify",


    }

    const myIntro = {
        width: "clamp(600px, 40vw, 1200px)",
    }

    let myName = {
        fontSize: "clamp(32px, 5rem, 100px)",
        // fontFamily: 'Roboto',    
        color: "white",
        fontWeight: "600",
        height: "60%",
        display: "flex",
        alignItems: "end"
    }
    let myTitle = {
        fontSize: "clamp(24px, 3rem, 75px)",
        display: "flex",
        justifyContent: "right",
        fontStyle: "italic"
    }
    let smallMessage = {
        fontSize: "clamp(28px, 2vh, 55px)",
        fontWeight: "200",
        // textIndent: "3rem",             


    }


    return <div style={initialLandingPageContentStyle}>
        <div style={myIntro}>
            <div style={myName}>
                Gabriel Hutu
            </div>
            <div style={myTitle}>
                - IAM Engineer
            </div>
            <div style={smallMessage}>
                Reach out to me for Professional Services regarding Identity Access and Management, Web Develpment and much more.
            </div>
        </div>
    </div>
}

export default initialLandingPageContent;